<template>
  <!-- 账户管理 -->
  <div class="purchaseManage">
    <!-- 板块中心区域 -->
    <div class="purchaseManage_mid">
      <div class="purchaseManage_header">
        <!-- 标题 -->
        <div class="title">
          <img src="../../assets/image/EquipmentStorage_slices/rectangle_31.png" alt="">
          <p v-for="(item, index) in $route.meta" :key="index">{{ item }}</p>
        </div>
        <!-- 选项框和按钮 -->
        <div class="option_btn">
          <div class="options">
            <!-- Element ui--Select 选择器 -->
            <!-- 类型 -->
            <template>
              <span style="margin:0 0.8vw 0 1.92vw">APP类型</span>
              <el-select v-model="params.type" placeholder="请选择" style="margin:0 1.45vw 0 0">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </template>
          </div>
          <!-- Element UI--Button按钮 -->
          <div class="btns" style="margin-right: 20px;">
            <el-button type="primary" @click="$refs.uploadAppDialogRef.open()">APP上传</el-button>
          </div>
        </div>
      </div>

      <!-- 表格区域 -->
      <div class="gateway_form">
        <lists ref="listsRef" :params.sync="params"></lists>
      </div>
      <uploadAppDialog ref="uploadAppDialogRef" @getList="$refs.listsRef.getTableData()"></uploadAppDialog>
    </div>
  </div>
</template>

<script>
// 引入列表页
import lists from "./components/lists.vue";
import uploadAppDialog from './components/uploadAppDialog.vue';
export default {
  components: {
    lists,
    uploadAppDialog
  },
  data() {
    return {
      params: {
        type: 1,
        page: 1,
        size: 20
      },
      options: [
        { value: 1, label: '用电用户侧' },
        { value: 3, label: '用户电工端' },
        { value: 2, label: '用电测试端' },
        { value: 4, label: '电工测试端' }
      ]
    };
  },

  mounted() {
  },

  methods: {

  },
};
</script>

<style scoped>
.el-table /deep/ .warning-row {
  background: #fff;
}

.el-table /deep/ .success-row {
  background: #f1f3f8;
}

.el-table /deep/ thead {
  font-family: "SHSCN_Regular";
  color: #fff;
  font-weight: normal;
}

.el-table /deep/ .cell {
  font-family: "SHSCN_Regular";
  font-size: 14px;
  font-weight: normal;
}

.lists .el-pagination {
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.lists /deep/ .el-pagination__jump {
  margin-left: 0;
}

.el-table /deep/ .el-table__cell {
  text-align: center;
  padding: 16px 0;
}


.purchaseManage {
  height: 100%;
  margin: -30px 0 0 0;
}

.purchaseManage .purchaseManage_mid {
  height: 100%;
}

/* 头部--开始 */
.purchaseManage_mid .purchaseManage_header {
  /* width: 100%; */
  height: 134px;
  background-color: #fff;
}

.purchaseManage_header .title {
  display: flex;
  align-items: flex-end;
}

.purchaseManage_header .title img {
  width: 6px;
  height: 20px;
  margin: 20px 0 0 20px;
}

.purchaseManage_header .title p {
  font-size: 20px;
  font-family: "SHSCN_Bold";
  margin: 1vw 0 0 0.6vw;
}

/* 选项框和按钮 */
.option_btn {
  /* width: 100%; */
  height: 7.2vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.option_btn /deep/ .el-input {
  width: 206px;
  height: 32px;
}

.options {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.option_btn /deep/ .el-input__icon {
  line-height: 1.85vh;
}

.option_btn span {
  font-size: 16px;
  font-family: "SHSCN_Regular";
}

/* 头部--结束 */

/* 表格区域--开始 */
.purchaseManage_mid .gateway_form {
  position: relative;
  height: calc(100vh - 222px);
  width: 100%;
  background-color: #fff;
  margin-top: 1vh;
}

/* 表格区域--结束 */

.lists /deep/ .el-pagination {
  position: absolute;
  bottom: 20px;
  right: 0;
  margin-top: 28px;
  height: 50px !important;
}
</style>