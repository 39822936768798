<!-- app上传 -->
<template>
    <div>
        <el-dialog title="APP上传" :visible.sync="dialogVisible" width="30%">
            <el-form ref="formRef" label-width="80px" :model="formLabelAlign" :rules="rules">
                <el-form-item label="类型" prop="type">
                    <el-select v-model="formLabelAlign.type">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="版本号" prop="vers">
                    <el-input v-model="formLabelAlign.vers"></el-input>
                </el-form-item>
                <el-form-item label="说明" prop="mark">
                    <el-input type="textarea" v-model="formLabelAlign.mark"></el-input>
                </el-form-item>
                <el-form-item label="apk包">
                    <el-upload class="upload-demo" ref="upload" action="/zt14/api/app/version/up"
                        :on-preview="handlePreview" :on-remove="handleRemove" :file-list="fileList" name="pack" :before-upload="beforeUpload"
                        :auto-upload="false" :headers="headers" :data="formLabelAlign" :limit="1" :on-success="handleSuccess">
                        <el-button slot="trigger" size="small" type="success">选取文件</el-button>
                        <div slot="tip" class="el-upload__tip">只能上传.apk文件</div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">关 闭</el-button>
                <el-button type="primary" @click="submitUpload">上 传</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'uploadAppDialog',
    data() {
        return {
            dialogVisible: false,
            formLabelAlign: {
                vers: '',
                mark: '',
                type: 1
            },
            options: [
                { value: 1, label: '用电用户侧' },
                { value: 3, label: '用户电工端' },
                { value: 2, label: '用电测试端' },
                { value: 4, label: '电工测试端' }
            ],
            loading: false,
            headers: {
                'Authorization': 'Bearer ' + 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJ3d3cubGlhbmJhbmdpb3QuY24iLCJhdWQiOiJ3d3cubGlhbmJhbmdpb3QuY24iLCJpYXQiOjE3MTc1Nzk0NDMsIm5iZiI6MTcxNzU3OTQ0MywiZXhwIjoxNzIwMTcxNDQzLCJleHRlbmQiOnsiaWQiOjEsImNpZCI6MSwiZ2lkIjoxLCJtaWQiOjEsInBob25lIjoiMTU2MDMzNzkyNDYiLCJ0eXBlIjowLCJmaW5hdGltZSI6bnVsbCwiZmluYWxpcCI6bnVsbCwiY3RpbWUiOiIyMDI0LTA1LTMxIDE4OjA0OjIyIiwiY3VzZXIiOiJcdThmZGVcdTkwYTZcdThmNmZcdTRlZjZcdWZmMDhcdTRlMGFcdTZkNzdcdWZmMDlcdTY3MDlcdTk2NTBcdTUxNmNcdTUzZjgiLCJzdGF0dXMiOjEsInBvd2VyIjotMSwicG93ZXJfbWVudSI6Ii0xIiwiaGVhZCI6ImhlYWQucG5nIiwiZW1haWwiOiJlZWVlQDE2My5jb20iLCJnbG9nbyI6Imh0dHBzOi8vd3d3LnFpeGluLmNvbS9jb21wYW55LzgzODUxMWMxLTAzNjItNDczYi1hOTQ0LTM2ZWM3YWYzNjEzZSIsImdzbmFtZSI6Ilx1NTU0YVx1NGU2Nlx1NmNkNVx1NTkyN1x1OGQ1Ylx1NTNkMSIsInJlbWFyayI6Ilx1OGZkOVx1NjYyZlx1NGUwMFx1NGUyYVx1ODBjY1x1NGU2Nlx1NzIzMVx1Nzk1ZVx1NzY4NFx1N2JhZGFhYVx1NTczMFx1NjViOVx1NTU0YSIsInB1c2hpZCI6bnVsbCwiYWNjZXNzX2V4cCI6MjU5MjAwMH19.6av8MXE3nP09lbmaAl0UKcJsv-0-tIYzeo08ngLrhxQ'
            },
            fileList: [],
            rules: {
                vers: [
                    { required: true, message: '请输入版本号', trigger: 'blur' }
                ],
                mark: [
                    { required: true, message: '请输入版本描述', trigger: 'blur' }
                ],
                type: [
                    { required: true, message: '请选择版本类型', trigger: 'change' }
                ]
            }
        }
    },
    methods: {
        submitUpload() {
            this.$refs.formRef.validate((valid) => {
                if (valid) {
                    this.$refs.upload.submit();
                }
            })
        },
        handleSuccess(response, file, fileList) {
            this.$message.success('上传成功')
            this.dialogVisible = false
            this.formLabelAlign = {
                vers: '',
                mark: '',
                type: 1
            }
            this.fileList = []
            this.$emit('getList')
        },
        beforeUpload(file) {
            console.log(file);
        },
        handleRemove(file, fileList) { // 文件列表移除文件时的钩子
            console.log(file, fileList);
        },
        handlePreview(file) { // 点击文件列表中已上传的文件时的钩子
            console.log(file);
        },
        open() {
            this.dialogVisible = true
        }
    }
}
</script>

<style lang='scss' scoped></style>
