<template>
  <!-- 账户管理-- 表格区域 -->
  <div class="lists">
    <!-- Element ui -- Form表单 -->
    <template>
      <el-table v-loading="loading" :data="tableData" style="width: 100%" :header-cell-style="{ background: '#4888FF' }"
        :max-height="tableHeight" :row-class-name="tableRowClassName">
        <el-table-column prop="type" label="类型">
          <template slot-scope="scope">
            <span>{{ types[scope.row.type] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="version" label="版本号">
        </el-table-column>
        <el-table-column prop="mark" label="说明">
        </el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" fixed="right" width="180">
          <template slot-scope="scope">
            <el-button type="text" @click="delHandle(scope.row)">删除</el-button>
            <el-button type="text" @click="downloadFile(scope.row.url)">下载</el-button>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <!-- Element ui--Pagination分页 -->
    <template>
      <el-pagination background @current-change="handleCurrentChange" @size-change="handleSizeChange"
        :current-page="params.page" :page-size="params.size" layout="total, prev, pager, next,sizes,jumper"
        :total="total">
      </el-pagination>
    </template>

    <el-dialog title="删除" :visible.sync="visibleDel" :close-on-click-modal="false">
      <div class="informInput">
        <h3>{{`确定要删除【${types[itemDetail.type]} v${itemDetail.version}】吗?`}}</h3>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="visibleDel = false">取 消</el-button>
        <el-button type="primary" @click="change(2)">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { appList, appDel } from '../../../utils/api'
export default {
  props: ['params'],
  data() {
    return {
      loading: true,
      total: 0,
      tableData: [],
      types: {
        1: '用电用户侧', 2: '用户电工端', 3: '用户电工端', 4: '电工测试端'
      },
      tableHeight: 0,
      visibleDel: false,
      itemDetail: {}
    };
  },
  created() {
    this.tableHeight = document.documentElement.clientHeight - 300
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.tableHeight = document.documentElement.clientHeight - 300;
    })
  },
  watch: {
    params: {
      handler(newVal) {
        this.getTableData()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    async getTableData() {
      this.loading = true
      this.tableData = []
      const res = await appList(this.params)
      this.tableData = res.data.data
      this.total = this.tableData.length
      this.loading = false
    },
    //隔行变色
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return "warning-row";
      } else if (rowIndex % 2 === 1) {
        return "success-row";
      }
      return "";
    },
    // 分页的方法
    handleSizeChange(val) {
      this.$emit('update:params', { ...this.params, size: val })
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.$emit('update:params', { ...this.params, page: val })
      console.log(`当前页: ${val}`);
    },
    delHandle(row) {
      this.itemDetail = row
      this.visibleDel = true
    },
    // 删除
    async change(status) {
      const res = await appDel({ status, id: this.itemDetail.id })
      if (res.code === 1) {
        this.$message.success('删除成功')
        this.visibleDel = false
        this.getTableData()
      } else {
        this.$message.error(res.msg)
      }
    },
    // 下载
    downloadFile(url) {
      let link = document.createElement('a');
      link.href = this.$URL + url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  },
};
</script>

<style scoped>
.el-table /deep/ .warning-row {
  background: #fff;
}

.el-table /deep/ .success-row {
  background: #f1f3f8;
}

.el-table /deep/ thead {
  font-family: "SHSCN_Regular";
  color: #fff;
  font-weight: normal;
}

.el-table /deep/ .cell {
  font-family: "SHSCN_Regular";
  font-size: 14px;
  font-weight: normal;
}

.lists .el-pagination {
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: "Source Han Sans CN-Regular";
}

.lists /deep/ .el-pagination__jump {
  margin-left: 0;
}

.el-table /deep/ .el-table__cell {
  text-align: center;
  padding: 16px 0;
}
</style>